<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";
import { GoogleMap, CustomMarker } from "vue3-google-map";
const { cookiesEnabledIds, isModalActive } = useCookieControl()

const googleMapEnabled = ref(cookiesEnabledIds.value?.includes('functional'));

watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (!previous?.includes('functional') && current?.includes('functional')) {
      googleMapEnabled.value = true;
    }
  },
  { deep: true },
)

const runtimeConfig = useRuntimeConfig();

type CmsBlockMaasStoreDetail = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasStoreDetail";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasStoreDetail;
}>();

const storeData = (props.content.slots?.[0] as any).config?.location?.value ?? {};

</script>

<template>
  <CmsElementTeaserCategoryWide
    :title="storeData.title"
    :subtitle="storeData.subtitle"
    position="right"
  >
    <template #richtext>
      <template v-if="storeData.businessHours">
        <h5 class="mt-4 text-h5 text-maas-typography-headline-primary">Öffnungszeiten</h5>
        <div class="richtext" v-html="storeData.businessHours" />
      </template>

      <template v-if="storeData.street || storeData.city">
        <h5 class="mt-4 text-h5 text-maas-typography-headline-primary">Adresse</h5>
        <p class="text-p">
          {{ storeData.street }}<br>
          {{ storeData.zip }} {{ storeData.city }}
        </p>
      </template>

      <template v-if="storeData.phone || storeData.email">
        <h5 class="mt-4 text-h5 text-maas-typography-headline-primary">Kontakt</h5>
        <p v-if="storeData.phone" class="text-p">Telefon: <a :href="'tel:' + storeData.phone" class="text-a">{{ storeData.phone }}</a></p>
        <p v-if="storeData.email" class="text-p">E-Mail: <a :href="'mailto:' + storeData.email" class="text-a">{{ storeData.email }}</a></p>
      </template>
    </template>

    <template #default>
      <nuxt-error-boundary @error="() => {}">
        <Swiper
          class="w-full h-full"
          :modules="[SwiperPagination, SwiperKeyboard]"
          :pagination="true"
          :loop="true"
          :keyboard="{ enabled: true }"
          :slides-per-view="1"
          :space-between="0"
          :grab-cursor="true"
        >
          <template  v-for="(image, index) in storeData.media" :key="index">
            <SwiperSlide>
              <SharedCmsImage :src="image.media.url" :width="960" :height="765" :crop="{ width: 960, height: 960, type: 'ce', x:0, y:0 }" class-img="object-cover w-full h-full" />
            </SwiperSlide>
          </template>
        </Swiper>
      </nuxt-error-boundary>
    </template>
  </CmsElementTeaserCategoryWide>

  <CmsElementTeaserCategoryWide :text="storeData.directions">
    <GoogleMap
      v-if="googleMapEnabled"
      :api-key="runtimeConfig.public.GOOGLE_MAPS_API_KEY"
      :mapTypeControl="false"
      :clickableIcons="false"
      :streetViewControl="false"
      :styles="[
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.medical',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.school',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        }
      ]"
      :center="{ lat: storeData.geoLat, lng: storeData.geoLong }"
      :zoom="17"
      class="w-full h-full"
    >
      <CustomMarker :options="{ position: { lat: storeData.geoLat, lng: storeData.geoLong }, anchorPoint: 'CENTER' }">
        <svg width="60" height="66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g class="stroke-maas-icon-primary">
            <path d="m43.523 51.908-.003.004a.017.017 0 0 1 .003-.004Zm-.818-.211c.335.11.565.213.703.303-.139.09-.368.194-.703.303-.691.226-1.719.436-3.02.616-2.595.358-6.196.581-10.185.581-3.989 0-7.59-.223-10.185-.581-1.301-.18-2.329-.39-3.02-.616-.335-.11-.565-.213-.704-.303.14-.09.369-.194.704-.303.691-.226 1.719-.436 3.02-.616 2.595-.358 6.196-.581 10.185-.581 3.989 0 7.59.223 10.185.581 1.301.18 2.329.39 3.02.616Zm-27.228.211.003.004a.02.02 0 0 1-.003-.004Zm0 .184.003-.004a.017.017 0 0 1-.003.004Zm28.043-.004a.02.02 0 0 1 .003.004l-.003-.004Z"/>
            <path d="M49.5 52c0 .094-.046.243-.256.449-.21.206-.549.429-1.03.656-.958.453-2.377.875-4.17 1.233C40.466 55.054 35.5 55.5 30 55.5s-10.466-.446-14.044-1.162c-1.793-.358-3.212-.78-4.17-1.233-.481-.227-.82-.45-1.03-.656-.21-.206-.256-.355-.256-.449 0-.094.046-.243.256-.449.21-.206.549-.429 1.03-.656.958-.453 2.377-.875 4.17-1.233C19.534 48.946 24.5 48.5 30 48.5s10.466.446 14.044 1.162c1.793.358 3.212.78 4.17 1.233.481.227.82.45 1.03.656.21.206.256.355.256.449Z"/>
          </g>
          <path class="fill-maas-icon-primary stroke-maas-icon-light" d="M29.5 1c-5.17.006-10.126 2.12-13.782 5.875-3.655 3.756-5.712 8.849-5.718 14.16a20.192 20.192 0 0 0 3.928 12.022s.532.72.62.823L29.5 52l14.96-18.129c.078-.096.612-.814.612-.814l.001-.005A20.185 20.185 0 0 0 49 21.036c-.006-5.312-2.063-10.405-5.718-14.16C39.626 3.118 34.67 1.005 29.5 1Z" />
          <path class="fill-maas-icon-light" fill-rule="evenodd" clip-rule="evenodd" d="M26.769 12.27c-.296.098-1.675.968-2.167 1.377-1.675 1.451-5.27 6.863-6.01 8.935-.61 1.756-.95 3.837 0 5.067.926-.206 1.29-.964 1.547-1.84-.098-.511-.749-.477-.616-1.23 2.168-4.182 4.236-8.462 8.472-10.607-.344 3.222-2.265 6.16-4.334 8.152.197 1.132.837 1.83 1.674 2.313 1.724-1.019 1.921-3.272 3.202-4.616 1.084-1.102 2.66-2.189 3.99-3.08 1.428-.983 3.25-1.672 5.221-1.87a5.19 5.19 0 0 1 0 2.461c-.64 2.362-3.152 5.953-4.482 7.675-.739.984-2.217 1.771-3.251 2.903-1.921 2.116-3.596 3.543-5.566 5.215 1.098.443 2.315 1.181 3.842.739.315-.099 1.891-1.477 2.463-2.018 1.206-1.18 2.448-3.05 3.857-4.92 1.33-1.771 2.689-3.936 3.383-5.412 1.084-2.165 2.601-4.92 1.764-7.036-3.448-2.312-7.586.738-9.852 1.525-.246-.934.32-2.066.148-3.394-.886-.541-2.069-.837-3.35-.394l.065.054Z" />
        </svg>
      </CustomMarker>
    </GoogleMap>

    <div v-else class="flex gap-4 flex-col justify-center items-center h-full s-gutter bg-maas-background-grey-medium text-center">
      <p class="text-p text-maas-typography-text-grey-normal">Bitte aktivieren Sie funktionale Cookies um die Karte anzuzeigen.</p>
      <SharedFormButton type="button" layout="secondaryDark" @click="isModalActive = true">Einstellungen öffnen</SharedFormButton>
    </div>
  </CmsElementTeaserCategoryWide>
</template>
